var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('page-content',{attrs:{"breadcrumbTitle":true,"breadcrumb":[
        {
          title: 'Components',
          link: '/components/components-page',
        },
        {
          title: 'Navigation',
        },
      ]},scopedSlots:_vm._u([{key:"desc",fn:function(){return [_c('p',{staticClass:"h5 mb-0 mt-24 hp-text-color-black-0"},[_vm._v(" Dropdowns are toggleable, contextual overlays for displaying lists of links and actions in a dropdown menu format. ")])]},proxy:true}])})],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('basic')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('button-content')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('menu-alignment')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('drop')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('offset')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('split')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('sizing')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('variants')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('block')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('hidden')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('dropdown-form')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }